import React from "react";
import { useTranslation } from "react-i18next";
import { Badge, BlockStack, Box, Button, Card, Divider, Icon, InlineStack, Text, Tooltip } from "@shopify/polaris";
import {
  AlertDiamondIcon,
  CheckCircleIcon,
  CheckIcon,
  ClockIcon,
  DiscountIcon,
  XIcon,
} from "@shopify/polaris-icons";
import { promocodeFormFields } from "@/Assets/Mocks/CommonPricing.mock";
import CommonForm from "@/Components/Common/CommonForm";

const Discount = (props) => {
  const { t } = useTranslation();
  promocodeFormFields[0].placeholder = t(`pricing.Enter Promocode`);

  const {
    plan,
    formRef,
    formValues,
    upgradePlan,
    cancelPlan,
    submitPromocode,
    trialDays,
    profileData,
    urlPromoCode,
  } = props;

  let trialText =
    profileData.trial_days === undefined || trialDays === 7
      ? t(`pricing.7 days free trial, No Credit card required`)
      : trialDays > 0 && trialDays < 7
      ? `${t("pricing.Your remaining trial days are")} ${trialDays}. ${t("pricing.You have used")} ${
          7 - trialDays
        } ${t("pricing.days.")}`
      : t(`pricing.No trial days remaining. You have already used the 7-day free trial.`);

  return (
    <React.Fragment>
      {plan.discountLabel && (
        <InlineStack blockAlign="center" align="space-between">
          <Text variant="headingSm" as="h3" tone="subdued">
            <span className="line-through">${plan.monthlyPrice ? plan.monthlyPrice : plan.price}</span>
          </Text>
          <InlineStack gap="100" blockAlign="center" wrap={false}>
            <Icon source={DiscountIcon} tone="success" />
            {plan.discountValue ? (
              <Text as="p" tone="success">
                {plan.discountLabel} {t(`pricing.Discount applied`)}
              </Text>
            ) : (
              <Text as="p" tone="success">
                {Math.round(100 - (plan.finalPrice * 100) / (plan.monthlyPrice || plan.price)) + "%"}{" "}
                {t(`pricing.Discount applied`)}
              </Text>
            )}
          </InlineStack>
        </InlineStack>
      )}
      {/* {!plan.isPromoInputHidden && !plan.selected && (
        <InlineStack align="space-between" gap={100}>
          <CommonForm
            onSubmit={submitPromocode}
            initialValues={formValues}
            formFields={promocodeFormFields}
            formRef={formRef}
            isSave={false}
          />
          <div className="pricing-apply--btn">
            <Button small onClick={() => submitPromocode(plan)}>
              {t(`pricing.Apply`)}
            </Button>
          </div>
        </InlineStack>
      )} */}
      {(plan.touched || urlPromoCode) && plan.discountValue && (
        <Text tone="success">
          {t(`pricing.Your code(s)`)} <b>{plan["discountValue"]}</b>{" "}
          {t(`pricing.and discount have been applied successfully`)}
        </Text>
      )}
      {plan.touched && !plan.discountValue && (
        <InlineStack gap="200" blockAlign="center" wrap={false}>
          <Icon source={AlertDiamondIcon} tone="critical" />
          <Text tone="critical"> {t(`pricing.Your entered promocode is not correct, Enter a valid code`)}</Text>
        </InlineStack>
      )}

      {plan.selected && plan.type === "recurring" && (
        <InlineStack gap="100" blockAlign="center" wrap={false}>
          <Icon source={ClockIcon} tone="base" />
          <Text as="p">
            {trialDays} {t(`pricing.days trial left`)}
          </Text>
        </InlineStack>
      )}
      {plan.type === "recurring" &&
        (plan?.disableActiveButton ? (
          <Tooltip content={t("pricing.disableButtonTooltip")} width="wide">
            <Button variant="primary" fullWidth disabled={true}>
              {t("pricing.Upgrade your plan now")}
            </Button>
          </Tooltip>
        ) : (
          <Button
            variant="primary"
            // tone="success"
            fullWidth
            onClick={() => (plan.selected ? cancelPlan(plan) : upgradePlan(plan))}
          >
            {plan.trial && !plan.selected
              ? trialDays === 0 && profileData.trial_days !== undefined
                ? t("pricing.Upgrade your plan now")
                : `${t("pricing.Start")} ${profileData.trial_days === undefined ? plan.trial.days : trialDays} ${t(
                    "pricing.Day Free Trial"
                  )}`
              : t(`pricing.Cancel Plan`)}
          </Button>
        ))}
      {plan.type === "onetime" && !plan.selected && (
        <Button variant="primary" fullWidth onClick={() => upgradePlan(plan)}>
          {t(`pricing.Upgrade`)}
        </Button>
      )}
      {plan.trial && plan.intervalLable !== "Unlimited" && !plan.selected && (
        <Text variant="headingSm" as="h6" alignment="center">
          {trialText}
        </Text>
      )}
    </React.Fragment>
  );
};

// Write Review Button :-
const writeReview = () => {
  const reviewURL = "https://apps.shopify.com/dakaas-social-media-icons#adp-reviews";
  window.open(reviewURL, "_blank");
};

export default function SinglePlan(props) {
  const { t } = useTranslation();
  const {
    plan,
    formRef,
    formValues,
    upgradePlan,
    cancelPlan,
    submitPromocode,
    trialDays,
    profileData,
    urlPromoCode,
  } = props;
  const { recurringPlanName } = profileData;

  return (
    <div className="pricing-card">
      <Card
        padding="400"
        // background={plan.selected && process.env.ISFREEAPP == 0 ? "bg-fill-success-secondary" : "bg-surface"}
      >
        <BlockStack gap="400">
          <InlineStack blockAlign="center" align="space-between">
            <Text variant="headingLg" as="h2">
              {plan.name}
            </Text>
            {plan.selected && <Badge tone="info">{t("pricing.features.Current")}</Badge>}
          </InlineStack>
          <InlineStack blockAlign="center" gap="100">
            <Text variant="heading2xl" as="h3">
              ${plan.finalPrice}
            </Text>
            {plan.intervalLable && (
              <Text variant="headingSm" as="h6" tone="subdued">
                /{t(`pricing.${plan.intervalLable}`)}
              </Text>
            )}
          </InlineStack>
          {plan.price !== 0 ? (
            <Discount
              plan={plan}
              formRef={formRef}
              formValues={formValues}
              upgradePlan={upgradePlan}
              cancelPlan={cancelPlan}
              submitPromocode={submitPromocode}
              trialDays={trialDays}
              profileData={profileData}
              urlPromoCode={urlPromoCode}
            />
          ) : process.env.ISFREEAPP == 0 ? (
            ({
              /* <Box minHeight="100px"></Box> */
            },
            process.env.ISFREEAPP == 0 && recurringPlanName === "Free" ? (
              <Button variant="primary" fullWidth disabled={true}>
                {t("dashboard.You are on Free Plan")}
              </Button>
            ) : (
              <Box minHeight="0px"></Box>
            ))
          ) : (
            <Box minHeight="0px" minWidth="280px"></Box>
          )}
          <Divider></Divider>
          <BlockStack gap="400">
            {plan.features.map((feature, index) => (
              <InlineStack gap="200" key={index} blockAlign="center" wrap={false}>
                {/* <Icon size="large" source={CheckCircleIcon} tone="success" className="custom-icon" /> */}
                {feature.icon ? (
                  <Icon size="large" source={XIcon} tone="critical" className="custom-icon" />
                ) : (
                  <Icon size="large" source={CheckIcon} tone="success" className="custom-icon" />
                )}
                {feature.dynamic ? (
                  <Text wrap as="p">
                    {eval(feature.dynamicText)} {t(`pricing.features.${feature.text}`)}
                  </Text>
                ) : (
                  <Text wrap as="p">
                    {t(`pricing.features.${feature.text}`)}
                  </Text>
                )}
              </InlineStack>
            ))}
            {process.env.ISFREEAPP == 1 ? (
              <Button variant="primary" onClick={writeReview}>
                {t("dashboard.Write Review")}
              </Button>
            ) : (
              ""
            )}
          </BlockStack>
        </BlockStack>
      </Card>
    </div>
  );
}
